import { Anchor, Box, Text } from "grommet";
import { usePage } from "@organisms/page/provider";
import styled from "styled-components";
import useIsLarge from "@cybertools/ui/es/hooks/useIsLarge";

const Card = styled(Box)`
  span {
    line-height: 12px;
  }
  &:hover {
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.4);
    border-color: white;
  }
`;

function Price({ value, ...rest }) {
  return (
    <Text size="xsmall" weight="normal" color="status-disabled" {...rest}>
      {value}
    </Text>
  );
}

export default function PriceList({ cardPadding, ...rest }) {
  const { coinscopeCoin } = usePage();
  const large = useIsLarge();

  if (!coinscopeCoin) return null;

  const { dollarPrice } = coinscopeCoin;

  return (
    <Box flex={false} {...rest}>
      <Anchor
        href="https://pancakeswap.finance/swap?outputCurrency=0xd41c4805a9a3128f9f7a7074da25965371ba50d5"
        target="_blank"
        rel="noopener"
        data-ga="priceList::click::coinscope"
      >
        <Card
          border={{ color: "#2E364D" }}
          pad={{ horizontal: "xsmall", vertical: "xsmall" }}
          round="4px"
          justify="between"
          direction={large ? "column" : "row"}
          {...cardPadding}
        >
          <Box direction="row">
            <Text size="xsmall">COINSCOPE</Text>
            <Price value={dollarPrice} margin={{ left: "xsmall" }} />
          </Box>
          <Text
            size="xsmall"
            weight="normal"
            color="brand"
            margin={{ top: "xxsmall" }}
          >
            BUY NOW
          </Text>
        </Card>
      </Anchor>
    </Box>
  );
}
