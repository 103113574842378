import { Anchor, Box, Button } from "grommet";
import Link from "next/link";
import dynamic from "next/dynamic";
import styled, { ThemeContext } from "styled-components";
import PriceList from "@components/priceList";
import Search from "@components/search";
import useAuth from "@organisms/auth/useAuth";
import Logo from "./logo";

const LoggedUser = dynamic(() => import("./loggedUser"));

const StyledAnchor = styled(Anchor)`
  padding: 12px;
  &:hover {
    color: ${({ theme }) => theme.global.colors.brand};
  }
`;

export default function TopBarDesktop() {
  const auth = useAuth();

  return (
    <Box
      direction="row"
      justify="between"
      pad={{ top: "medium", horizontal: "medium" }}
      width={{ width: "100%", max: "xxlarge" }}
    >
      <Box direction="row" align="center">
        <Logo />
        <PriceList margin={{ left: "small" }} />
      </Box>
      <Search flex margin={{ horizontal: "medium" }} />
      <Box direction="row" align="center">
        <ThemeContext.Extend value={{ global: { colors: { text: "white" } } }}>
          <Link href="/promote" passHref legacyBehavior>
            <Button label="Promote" primary />
          </Link>
        </ThemeContext.Extend>
        <Link href="/addcoin" passHref legacyBehavior>
          <StyledAnchor
            margin={{ left: "xsmall" }}
            label="Add Coin"
            color="white"
          />
        </Link>
        {auth.user ? (
          <LoggedUser gaCategory="headerLarge" />
        ) : (
          <Box justify="center">
            <Link href="/login" passHref legacyBehavior>
              <StyledAnchor label="Login" color="white" />
            </Link>
          </Box>
        )}
      </Box>
    </Box>
  );
}
