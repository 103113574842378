import { useState, useCallback, useMemo } from "react";
import { useRouter } from "next/router";
import { Search as SearchIcon } from "grommet-icons";
import { Box, TextInput } from "grommet";
import styled from "styled-components";
import axios from "axios";
import debounce from "lodash.debounce";
import getCss from "components/loading/css";
import useFetchFnPromise from "@cybertools/ui/es/hooks/useFetchFnPromise";
import { reportEvent } from "@cybertools/ui/es/hooks/useTrackEvents";
import Coin from "./coin";

const emptyArray = [];

const loading = getCss({ duration: "0.6s" });

const Container = styled(Box).attrs({
  border: { side: "all", color: "#2E364D" },
  round: "large",
  justify: "center",
})`
  &:focus-within {
    border-color: #798197;
  }
  /* background: #313239; */
  ${({ isLoading }) => isLoading && loading}

  input::placeholder {
    color: #b6adad;
  }
  input {
    padding: 13px 32px 13px 24px;
  }
`;

// const StyledTextInput = styled(TextInput)`
//   height: 100%;
//   border: 1px solid #2e364d;
//   border-radius: 37px;
// `;

const makeSuggestion = ({
  coin: { icon, name, symbol, slug },
  isLast,
  includeSlug,
}) => ({
  label: (
    <Coin
      icon={icon}
      name={name}
      symbol={symbol}
      slug={slug}
      isLast={isLast}
      includeSlug={includeSlug}
    />
  ),
  value: slug,
});

export default function Search({
  value: initialValue = "",
  onChange,
  // width,
  // size,
  autoFocus,
  disabled,
  includeSlug = false,
  ...rest
}) {
  const router = useRouter();
  const [value, setValue] = useState(initialValue);

  const [fetch, data, loading] = useFetchFnPromise(
    {
      action: (v) =>
        axios.get(`/api/search?q=${v}${includeSlug ? "&include=slug" : ""}`),
      initialData: emptyArray,
    },
    []
  );

  const setDebounce = useMemo(
    () =>
      debounce((newValue) => {
        if (newValue.length > 2) fetch(newValue);
        reportEvent("search", "typed", newValue);
      }, 400),
    []
  );

  const suggestions = useMemo(
    () =>
      data.map((coin, index) =>
        makeSuggestion({ coin, isLast: index === data.length - 1, includeSlug })
      ),
    [data]
  );

  const change = useCallback((event) => {
    const { value: newValue } = event.target;
    setValue(newValue);
    setDebounce(newValue);
  }, []);

  const onSuggestionSelect = useCallback(
    (event) => {
      const slug = event.suggestion.value;

      if (onChange) return onChange(data.find((d) => d.slug === slug));

      router.push(`/coin/${slug}`);
      reportEvent("search", "selected", slug);
    },
    [data]
  );

  return (
    <Container
      width={{ width: "100%", max: "380px" }}
      isLoading={loading}
      {...rest}
    >
      <TextInput
        dropProps={{
          elevation: "none",
          background: "white",
          border: { side: "bottom" },
          round: "xsmall",
          stretch: true,

          // width,
          margin: { top: "xsmall" },
          zIndex: 200,
        }}
        disabled={disabled}
        autoFocus={autoFocus}
        icon={<SearchIcon size="16px" color="#2E364D" />}
        placeholder={`Search coin, symbol${includeSlug ? ", slug" : ""}...`}
        plain={true}
        value={value}
        onChange={change}
        suggestions={suggestions}
        onSelect={onSuggestionSelect}
        size="small"
        reverse
      />
    </Container>
  );
}
