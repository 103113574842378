import { Box, Avatar, Text } from "grommet";
import styled from "styled-components";

const StyledAvatar = styled(Avatar)`
  flex-shrink: 0;
`;

export default function Coin({
  icon,
  name,
  symbol,
  slug,
  size,
  isLast,
  ...rest
}) {
  return (
    <Box
      direction="row"
      align="center"
      gap="small"
      border={isLast ? false : { color: "#EBE9E9", side: "bottom" }}
      pad="small"
      elevation=""
      {...rest}
    >
      <StyledAvatar size={size === "small" ? "small" : "32px"} src={icon} />
      <Box>
        <Text size={size === "small" ? "small" : "small"} truncate>
          {name}
        </Text>
        <Text
          color="#676C79"
          size={size === "small" ? "xsmall" : "xsmall"}
          truncate
        >
          ${symbol}・{slug}
        </Text>
      </Box>
    </Box>
  );
}
