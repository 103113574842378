import { useFetchTrending } from "@organisms/coin/actions";
import { TrendingTokens, Coins, Container, CoinsPlaceholder } from "./trending";

export default function TrendingDesktop(props) {
  const [slugs, loaded] = useFetchTrending();

  return (
    <Container direction="row" gap="large" {...props}>
      <TrendingTokens />
      {loaded ? <Coins coins={slugs} /> : <CoinsPlaceholder />}
    </Container>
  );
}
