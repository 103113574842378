import { Box } from "grommet";
import TopBarDesktop from "./topBarDesktop";
import Navigation from "./navigation";
import TrendingDesktop from "./trendingDesktop";

export default function HeaderDesktop(props) {
  return (
    <Box
      border={{ side: "bottom", color: "#526085" }}
      align="center"
      {...props}
    >
      <TrendingDesktop />
      <TopBarDesktop />
      <Navigation />
    </Box>
  );
}
